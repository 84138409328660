import request from '@/utils/request'

// 查询称号分组配置列表
export function listAppellationGroup(query) {
  return request({
    url: '/config/appellation/group/list',
    method: 'get',
    params: query
  })
}

// 查询称号分组配置详细
export function getAppellationGroup(id) {
  return request({
    url: '/config/appellation/group/' + id,
    method: 'get'
  })
}

// 新增称号分组配置
export function addAppellationGroup(data) {
  return request({
    url: '/config/appellation/group',
    method: 'post',
    data: data
  })
}

// 修改称号分组配置
export function updateAppellationGroup(data) {
  return request({
    url: '/config/appellation/group',
    method: 'put',
    data: data
  })
}

// 删除称号分组配置
export function delAppellationGroup(id) {
  return request({
    url: '/config/appellation/group/' + id,
    method: 'delete'
  })
}
